import React from 'react';
import './App.css';

function App() {
  const JWL: string = 'https://jwlstrategies.com';
  const LinkedIn: string = 'https://www.linkedin.com/in/jacqueline-landry-0457504/';

  return (
    <div className="App App-header">
      <div className='container'>

      <h1>Hi, I'm Jacqueline Landry</h1>
      <p className='App-body'>an Independent consultant specializing in strategic planning, operations, project management, marketing, event management, website creation, and business development.</p>
      <p>
        <a className='App-link' href={JWL} target='_blank' rel="noreferrer">JWL Strategies</a><span> | </span>
        <a className='App-link' href={LinkedIn} target='_blank' rel="noreferrer">LinkedIn</a>
      </p>
      </div>

    </div>
  );
}

export default App;
